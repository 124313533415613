var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-container"},[_c('el-col',{attrs:{"sm":24,"md":16}},[_c('el-card',{staticClass:"box-card"},[_c('div',{staticClass:"clearfix",attrs:{"slot":"header"},slot:"header"},[_c('span',[_vm._v("Nueva noticia")])]),_c('div',{staticClass:"text item"},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('el-form',{attrs:{"label-position":"top","label-width":"100px","model":_vm.form}},[_c('el-form-item',{attrs:{"label":"Título","size":"mini"}},[_c('ValidationProvider',{attrs:{"name":"Titulo","rules":"required|min:1|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('el-form-item',{attrs:{"label":"Descripción corta","size":"mini"}},[_c('ValidationProvider',{attrs:{"name":"decription short","rules":"required|min:1|max:250"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('el-form-item',{attrs:{"label":"Imagen Miniatura"}},[(_vm.form.image)?_c('div',[_c('el-image',{attrs:{"src":_vm.$urlGlobalApi +_vm.form.image}},[_c('div',{staticClass:"image-slot",attrs:{"slot":"image"},slot:"image"},[_vm._v(" Loading"),_c('span',{staticClass:"dot"},[_vm._v("...")])])])],1):_vm._e(),_c('el-upload',{attrs:{"drag":"","action":"","name":"image","http-request":_vm.postImage,"on-success":_vm.handleAvatarSuccess,"multiple":""}},[_c('i',{staticClass:"el-icon-upload"}),_c('div',{staticClass:"el-upload__text"},[_vm._v("Suelta tu foto aquí o "),_c('em',[_vm._v("haz clic para cargar")])]),_c('div',{staticClass:"el-upload__tip",attrs:{"slot":"tip"},slot:"tip"},[_vm._v("Solo archivos jpg/png con un tamaño menor de 500kb (300 x 300)")])])],1),_c('el-form-item',{attrs:{"label":"Contenido"}},[_c('tinyeditor',{attrs:{"api-key":"epme9s1ejp9yit6h3lokaj05yohsvjm61r9y7kdrvm0wm9ex","init":{
                      height: 500,
                      plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount'
                      ],
                      toolbar:
                        'undo redo | formatselect | bold italic backcolor | \
                        alignleft aligncenter alignright alignjustify | \
                        bullist numlist outdent indent | removeformat | help'
                    }},model:{value:(_vm.form.content),callback:function ($$v) {_vm.$set(_vm.form, "content", $$v)},expression:"form.content"}})],1),_c('el-form-item',{attrs:{"label":"Link Video"}},[_c('el-input',{attrs:{"size":"mini","placeholder":"https"},model:{value:(_vm.form.video),callback:function ($$v) {_vm.$set(_vm.form, "video", $$v)},expression:"form.video"}}),_c('span',[_c('strong',[_vm._v("https")]),_vm._v("://vimeo.com/578105796")]),_c('br'),_c('span',[_vm._v("Llenar este campo no es obligatorio")])],1),_c('el-form-item',{attrs:{"label":"Tiempo de Lectura"}},[_c('ValidationProvider',{attrs:{"name":"Tiempo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('el-input',{attrs:{"size":"mini"},model:{value:(_vm.form.timeRead),callback:function ($$v) {_vm.$set(_vm.form, "timeRead", $$v)},expression:"form.timeRead"}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('el-form-item',{attrs:{"label":"Palabras Clave"}},[_c('ValidationProvider',{attrs:{"name":"Palabras Clave","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('el-select',{attrs:{"size":"mini","multiple":"","filterable":"","allow-create":"","placeholder":"Comunicación"},model:{value:(_vm.form.tags),callback:function ($$v) {_vm.$set(_vm.form, "tags", $$v)},expression:"form.tags"}},_vm._l((_vm.optionsTags),function(itemT){return _c('el-option',{key:itemT.value,attrs:{"label":itemT.label,"value":itemT.value}})}),1),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('el-form-item',{attrs:{"label":"Fecha de publicación"}},[_c('ValidationProvider',{attrs:{"name":"Fecha de publicación","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('el-date-picker',{attrs:{"size":"mini","type":"date","format":"dd-MM-yyyy","placeholder":"Fecha"},model:{value:(_vm.form.datePublication),callback:function ($$v) {_vm.$set(_vm.form, "datePublication", $$v)},expression:"form.datePublication"}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('el-form-item',{attrs:{"label":"Estatus"}},[_c('el-switch',{attrs:{"active-color":"#13ce66","inactive-color":"#ff4949"},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}})],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary","disabled":invalid,"icon":"el-icon-upload2"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onStore($event)}}},[_vm._v(" Crear")])],1)],1)]}}])})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }