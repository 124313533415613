<template>
<div class="app-container">
  <el-col :sm="24" :md="16" >
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>Nueva noticia</span>
      </div>
      <div class="text item">
        <ValidationObserver ref="form" v-slot="{ invalid }">
              <el-form label-position="top" label-width="100px" :model="form">
              <el-form-item label="Título" size="mini">
                <ValidationProvider name="Titulo" rules="required|min:1|max:100" v-slot="{ errors }">
                  <el-input v-model="form.title"></el-input>
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>
              </el-form-item>
              <el-form-item label="Descripción corta" size="mini">
                <ValidationProvider name="decription short" rules="required|min:1|max:250" v-slot="{ errors }">
                  <el-input v-model="form.description"></el-input>
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>
              </el-form-item>
              <el-form-item label="Imagen Miniatura">
                <div v-if="form.image">
                  <el-image :src="$urlGlobalApi +form.image">
                    <div slot="image" class="image-slot">
                      Loading<span class="dot">...</span>
                    </div>
                  </el-image>
                </div>
                <el-upload
                  drag
                  action=""
                  name="image"
                  :http-request="postImage"
                  :on-success="handleAvatarSuccess"
                  multiple>
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">Suelta tu foto aquí o <em>haz clic para cargar</em></div>
                  <div slot="tip" class="el-upload__tip">Solo archivos jpg/png con un tamaño menor de 500kb (300 x 300)</div>
                </el-upload>
              </el-form-item>
              <el-form-item label="Contenido">
                <tinyeditor v-model="form.content"
                  api-key='epme9s1ejp9yit6h3lokaj05yohsvjm61r9y7kdrvm0wm9ex'
                    :init="{
                      height: 500,
                      plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount'
                      ],
                      toolbar:
                        'undo redo | formatselect | bold italic backcolor | \
                        alignleft aligncenter alignright alignjustify | \
                        bullist numlist outdent indent | removeformat | help'
                    }"
                  />
              </el-form-item>
              <el-form-item label="Link Video">
                <!-- <ValidationProvider name="ID Video" rules="required" v-slot="{ errors }">
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>-->
                <el-input size="mini" v-model="form.video" placeholder="https"></el-input>
                <span><strong>https</strong>://vimeo.com/578105796</span><br>
                <span>Llenar este campo no es obligatorio</span>
              </el-form-item>
              <el-form-item label="Tiempo de Lectura">
                <ValidationProvider name="Tiempo" rules="required" v-slot="{ errors }">
                 <el-input size="mini" v-model="form.timeRead"></el-input>
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>
              </el-form-item>
              <el-form-item label="Palabras Clave">
                <ValidationProvider name="Palabras Clave" rules="required" v-slot="{ errors }">
                  <el-select
                  size="mini"
                  v-model="form.tags"
                  multiple
                  filterable
                  allow-create
                  placeholder="Comunicación">
                    <el-option
                      v-for="itemT in optionsTags"
                      :key="itemT.value"
                      :label="itemT.label"
                      :value="itemT.value">
                    </el-option>
                  </el-select>
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>
              </el-form-item>
              <el-form-item label="Fecha de publicación">
                <ValidationProvider name="Fecha de publicación" rules="required" v-slot="{ errors }">
                  <el-date-picker
                    size="mini"
                    v-model="form.datePublication"
                    type="date"
                    format="dd-MM-yyyy"
                    placeholder="Fecha">
                  </el-date-picker>
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>
              </el-form-item>
              <el-form-item label="Estatus">
                <el-switch
                  v-model="form.status"
                  active-color="#13ce66"
                  inactive-color="#ff4949">
                </el-switch>
              </el-form-item>
              <el-form-item>
              <el-button
                @click.stop.prevent="onStore"
                type="primary"
                :disabled="invalid"
                icon="el-icon-upload2">
                Crear</el-button>
              </el-form-item>
          </el-form>
        </ValidationObserver>
      </div>
    </el-card>
  </el-col>
</div>
</template>
<script>
import { storeNews } from '@/api/news'
import { uploadImageFile } from '@/api/image'

export default {
  data () {
    return {
      limitTitle: 65,
      limitDescription: 155,
      form: {
        title: '',
        description: '',
        thumb: '',
        image: '',
        video: '',
        content: '',
        timeRead: '',
        tags: [],
        datePublication: '',
        status: true,
        likes: []
      },
      optionsTags: [{
        value: 'Soporte',
        label: 'Soporte'
      }, {
        value: 'Marketing',
        label: 'Marketing'
      }, {
        value: 'Ventas',
        label: 'Ventas'
      }, {
        value: 'Finanzas',
        label: 'Finanzas'
      }, {
        value: 'Apps',
        label: 'Apps'
      }],
      loading: false,
      loadingDelete: false,
      isVisibleCollection: false,
      formEditCollection: {}
    }
  },
  created () {
    // this.form.admin_id = this.$store.getters.id
  },
  methods: {
    async postImage (param) {
      this.loading = true
      const formData = new FormData()
      formData.append('image', param.file)
      formData.append('folder', 'img/news')
      formData.append('width', '800')
      formData.append('height', '600')
      formData.append('title', 'news')
      formData.append('alt', 'imagen-news')
      uploadImageFile(formData).then((response) => {
        console.log(response)
        param.onSuccess(response.data)
      })
        .catch(response => {
          console.log(response)
          param.onError()
        })
        .finally(() => (this.loading = false))
    },
    async onStore () {
      await storeNews(this.form)
        .then(() => {
          // let res = response.data
          this.$message({
            showClose: true,
            message: 'Se ha creado una nueva Noticia!',
            type: 'success'
          })
          this.$router.push({ name: 'news' })
        })
        .catch(this.responseCatch)
    },
    handleAvatarSuccess (res, file) {
      this.form.image = res.path
      this.form.thumb = res.pathThumb
      // console.log(this.form.image)
    }
  }
}
</script>
